    .concert-program {
    text-align: center;
    padding: 20px;
  }
  
  h1 {
    color: #f2f2f2; /* Couleur plus douce que noir */
    font-size: 3rem;
    margin-bottom: 40px;
    background: linear-gradient(45deg, #e74c3c, #3498db); /* Dégradé de couleur */
    -webkit-background-clip: text;
    color: transparent; /* Rendre le texte lui-même transparent pour montrer le dégradé */
  }
    .concert-list {
    display: flex;
    justify-content: center;
    flex-wrap: wrap;
  }
  
  .concert-card {
    background-color: #ffffff;
    border-radius: 15px;
    box-shadow: 0 8px 16px rgba(0, 0, 0, 0.15); /* Ombre douce et légère */
    margin: 15px;
    padding: 25px;
    width: 320px;
    transition: transform 0.3s ease, box-shadow 0.3s ease;
    position: relative;
    overflow: hidden;
  }
  
  .concert-card:hover {
    transform: translateY(-8px);
    box-shadow: 0 12px 24px rgba(0, 0, 0, 0.2);
  }
  
  .concert-card::before {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 5px;
    background: linear-gradient(90deg, #e74c3c, #3498db); /* Une ligne dégradée colorée en haut de la carte */
  }
  
  .concert-card h2 {
    color: #3498db; /* Couleur de texte bleue pour un contraste élégant */
    font-size: 1.7rem;
    margin-bottom: 15px;
  }
  
  .concert-card p {
    color: #2c3e50;
    font-size: 1.1rem;
    margin: 5px 0;
  }
  
  .concert-card p span {
    color: #e74c3c; /* Utiliser le rouge pour faire ressortir certaines informations */
    font-weight: bold;
  }