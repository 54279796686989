/* override browser default */
html,
body {
  margin: 0;
  padding: 0;
  background-image:url(../public/manger.JPG);
  background-size:cover;
}

/* use viewport-relative units to cover page fully */
body {
  height: 100vh;
  width: 100vw;
}

.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}
.cursor-pointer{
  cursor: pointer;
}
.input-password {
	position: relative;
}
.view-password {
	position: absolute;
	right: 0.5rem;
	top: 2rem;
	border: none;
	background: none;
	color: grey;
	cursor: pointer;
}

.view-password:focus {
	outline: 0;
}

.page-layout {
	position: relative;
  height: 100%;
	margin: 0;
	padding: 0;
}