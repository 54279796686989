/*****************************/
//////////   COLORS  /////////
/*****************************/

$primary-color: rgb(61, 63, 74);
$secondary-color: #00a0e3;
$light-color: #f6f9fd;
$contrast-color: #fa5456;
$light-contrast-color: #fef0dd;
$grey: LightSlateGrey;
$white: #fff;
$black: #000;

// CUSTOMIZE PRIMARY COLOR FROM BOOTSTRAP
$blue: #00a0e3 !important;
$green: #50c878 !important;
$violet: #b97cfc !important;
$yellow: #ffae42 !important;
$marine: #2748a0 !important;
$darkred: #a70b22;
$lightgreen: #9adfb1 !important;

/*****************************/
//////////   FONTS  //////////
/*****************************/

$title-font: "NotoSansBold", sans-serif;
$text-font: "NotoSans", sans-serif;
$light-font: "NotoSansLight", sans-serif;

// RESPONSIVE FONT SIZE
@mixin fluid-type($font-min, $font-max) {
	font-size: #{$font-min};

	@media only screen and (min-width: 600px) {
		font-size: calc(#{$font-min} + #{($font-max - $font-min)} * (100vw - 600px) / (1200 - 600));
	}

	@media only screen and (min-width: 1200px) {
		font-size: #{$font-max};
	}
}
.custom-table {
	color: $primary-color;
	background-color: white;
	font-family: $text-font;
	width: 100%;
	margin-top: 0.5rem;
	border: solid 1px darken($color: $black, $amount: 5);
	overflow: visible;
	th {
		padding: 0.7rem 0 0 0;
		@include fluid-type(0.85rem, 0.87rem);
		line-height: 1.35rem;
		font-family: $text-font;
		font-family: $title-font;
		color: $primary-color;
		background-color: $light-color;

		span {
			padding: 0.5rem;
		}

		.filter-options {
			margin: 0.5rem;
			width: 90%;
		}
	}

	td {
		@include fluid-type(0.8rem, 0.85rem);
		padding: 0.7rem 0 0.5rem 0.5rem;
	}

	tr:hover {
		background-color: $primary-color;
	}
}

.table thead th {
	border: none;
}

.table td {
	border-top: none;
	border-bottom: solid 1px #dee2e6;
}

.table-pagination {
	span {
		color: $primary-color;
		@include fluid-type(0.8rem, 0.85rem);
		line-height: 1.35rem;
		font-family: $text-font;
		padding: 0 0.5rem;
		margin: 0 0.5rem;
	}

	.table-button {
		background: $white;
		color: $primary-color;
		@include fluid-type(0.8rem, 0.85rem);
		line-height: 1.35rem;
		border-radius: 4px;
		border: solid 1px lighten($color: $grey, $amount: 40);
		text-align: center;
		text-decoration: none;
		padding: 0.15rem 0.5rem;
		margin: 0.25rem;
		transition-duration: 0.4s;
		cursor: pointer;
	}

	.table-button:hover {
		background-color: lighten($color: $grey, $amount: 30);
		color: $white;
		border: solid 1px lighten($color: $grey, $amount: 30);
	}

	.table-button:focus {
		outline: 0;
	}
}

.table-button-icon {
	width: 1rem;
	height: auto;
	cursor: pointer;
	font-family: $text-font;
	margin-right: 0.5rem;
	vertical-align: middle;
}

.search-table-bar {
	margin-top: 1rem;
}

.form-customized {
	@include fluid-type(0.85rem, 0.87rem);
	line-height: 1.35rem;
	color: $grey;
}

.table-icon {
	width: auto;
	height: 1.15rem;
	margin: 0 0.25rem;
	cursor: pointer;
	color: $primary-color;
	display: inline-block;
}

.table-icon:hover {
	color: $blue;
}
